// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';

// Utility to retrieve cookie value by name
const getCookieValue = (name) => document.cookie
	.split('; ')
	.find(row => row.startsWith(`${name}=`))
	?.split('=')[1];

const UserExit = () => {
	useEffect(() => {
		const handleBeforeUnload = () => {
			const userId = getCookieValue('user_id'); // Retrieve user_id from cookies

			// Retrieve GA Measurement IDs from localStorage
			const singleStreamId = localStorage.getItem('singleStreamId'); // Assuming you've set this in localStorage somewhere else in your app
			const universalGAID = localStorage.getItem('universalGAID'); // Assuming you've set this in localStorage somewhere else in your app

			// Assuming gtag.js is already set up and configured
			window.gtag && window.gtag('event', 'UserExit', {
				'event_category': 'OverallSiteEvents',
				'event_action': 'exit_attempt',
				'event_label': 'Before unload',
				'page_location': window.location.href,
				'user_id': userId,
				// Use the retrieved GA Measurement IDs in the send_to parameter
				'send_to': [singleStreamId, universalGAID].filter(id => id) // Filter out any null/undefined IDs
			});
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, []);

	return null; // This hook doesn't render anything
};

export default UserExit;