import React from 'react';
import { useTranslation } from "react-i18next";
import {
    LiveChatWidget,
    useWidgetState
} from '@livechat/widget-react';

const LiveChat = () => {
    const widgetState = useWidgetState();
    const { t } = useTranslation();
    const availability = widgetState?.availability;
    const style = availability === 'online' ? {
        cursor: 'pointer'
    } : {};
    return (
        <div>
            <button
                id="chatButton"
                style={style}
                className="LiveChatButton"
                onClick={() => {
                    if (availability === 'online') {
                        window.LiveChatWidget.call("maximize");
                    }
                }}
            >
                {availability === 'online' ? t('LiveChat.online') : t('LiveChat.offline')}
            </button>
            <LiveChatWidget
                license={'3635761'}
                group={'8'}
            />
        </div>
    );
};

export default LiveChat;