import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { sessionExpires } from '../AvsPage.js';
import SupportService from '../../../components/SupportService/SupportService.js';
import { ROUTES } from "../../../services/navigation/navigation.routes";
import { useGtag } from '../../../hook/useGtag.js';

const AvsSuccess = () => {
	const [countdown, setCountdown] = useState(17);
	const [redirectUrl, setRedirectUrl] = useState('');
	const [deepLinkChecked, setDeepLinkChecked] = useState(false);
	const [deepAvsLinkUrl, setDeepAvsLinkUrl] = useState(null);
	const [tubularUrl, setTubularUrl] = useState(null);
	const [joinAvsLinkChangedUrl, setjoinAvsLinkChangedUrl] = useState(null);

	useGtag({
		data: {
			event: 'page_view',
			page_title: 'AVS Success',
			page_location: window.location.href,
			page_path: window.location.pathname,
			event_label: 'success_page_loaded',
			event_category: 'age_verification',
			verification_stage: 'success_page_loaded'
		}
	});

	useEffect(() => {
		console.log('useEffect called');

		const sanitizeUrl = (url) => {
			const urlObj = new URL(url);
			const params = new URLSearchParams(urlObj.search);

			const natsValue = params.get('nats');
			params.delete('nats');  // Remove the NATS parameter temporarily

			// Optionally remove Google tracking parameters
			params.delete('_gl');
			params.delete('gclid');
			params.delete('fbclid');

			const remainingParams = params.toString();
			let sanitizedUrl = `${urlObj.origin}${urlObj.pathname}`;

			if (natsValue) {
				sanitizedUrl += `?nats=${natsValue}`;
			}

			if (remainingParams) {
				sanitizedUrl += `&${remainingParams}`;
			}

			return sanitizedUrl;
		};

		const getCookieDetails = (name) => {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; ${name}=`);
			if (parts.length === 2) {
				const cookieValue = parts.pop().split(';').shift();
				const cookieDate = new Date(parts[0].split(';').pop().split('=').pop());
				return { value: cookieValue, date: cookieDate };
			}
			return null;
		};

		const cookies = [
			{ name: 'deepAvsLink', ...getCookieDetails('deepAvsLink') },
			{ name: 'tubular', ...getCookieDetails('tubular') },
			{ name: 'joinAvsLinkChanged', ...getCookieDetails('joinAvsLinkChanged') },
		];

		const validCookies = cookies.filter(cookie => cookie.value && cookie.date);

		if (validCookies.length > 0) {
			validCookies.sort((a, b) => b.date - a.date); // Sort cookies by date, most recent first
			const mostRecentCookie = validCookies[0];
			setRedirectUrl(sanitizeUrl(decodeURIComponent(mostRecentCookie.value)));
		} else {
			setRedirectUrl(ROUTES.root); // Fallback to ROUTES.root
		}

		if (cookies[0].value) setDeepAvsLinkUrl(decodeURIComponent(cookies[0].value));
		if (cookies[1].value) setTubularUrl(decodeURIComponent(cookies[1].value));
		if (cookies[2].value) setjoinAvsLinkChangedUrl(decodeURIComponent(cookies[2].value));

		setDeepLinkChecked(true); // Indicates that deep link check is complete

		// Countdown logic
		const timerId = setTimeout(() => {
			if (countdown === 0) {
				console.log('Redirecting to:', redirectUrl);
				if (redirectUrl) {
					window.location.href = redirectUrl; // Redirect to the specified URL
				}
			} else {
				setCountdown((prevCountdown) => prevCountdown - 1);
			}
		}, 1000);

		return () => clearTimeout(timerId);
	}, [countdown, redirectUrl]);

	if (deepLinkChecked && !redirectUrl) {
		navigate(ROUTES.root); // Ensures the component does not stay in a loop
		return null; // Return null to avoid rendering the component before navigation
	}

	return (
		<>
			<div className="Content">
				<div className="Heading">AGE VERIFICATION COMPLETE</div>
				<p>Thank you for verifying your age with our partner Yoti.</p>
				<p><strong>Your status is: COMPLETE</strong></p>
				<p>Your session is valid until: {sessionExpires()}</p>
				<p><img src="https://spotlight.nakedcdn.com/nakedsword/img/yotilogo.jpg" width="138" height="63" alt="Yoti Logo" /></p>
				<p>If not automatically redirected in <span style={{ display: "inline-block", minWidth: "25px" }}>{countdown}</span> seconds, please <a href={redirectUrl || "#"} className="Link"><strong>click here</strong></a> to proceed.</p>
				<p><strong>Redirect URL:</strong> {redirectUrl || "None"}</p>
				<p><strong>DeepAvsLink URL:</strong> {deepAvsLinkUrl || "None"}</p>
				<p><strong>Tubular URL:</strong> {tubularUrl || "None"}</p>
				<p><strong>joinAvsLinkChanged URL:</strong> {joinAvsLinkChangedUrl || "None"}</p>
			</div>
			<SupportService />
		</>
	);
};

AvsSuccess.displayName = "AvsSuccess";

export default AvsSuccess;