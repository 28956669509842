export function isUserEngaged() {
	const minSessionDuration = 10 * 1000; // 10 seconds in milliseconds
	const minPageViews = 2;

	const sessionStartTime = parseInt(localStorage.getItem('session_start_time'), 10);
	const sessionDuration = Date.now() - sessionStartTime;
	const pageViews = parseInt(localStorage.getItem('page_views'), 10) || 0;

	return sessionDuration > minSessionDuration || pageViews >= minPageViews;
}

export function generateUniqueId() {
	return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function(c) {
		const r = Math.random() * 16 | 0,
			v = c === 'x' ? r : ((r & 0x3) | 0x8);
		return v.toString(16);
	});
}

export function getNonLoggedInUserId() {
	let userId = localStorage.getItem('non_logged_in_user_id');
	if (!userId) {
		userId = generateUniqueId();
		localStorage.setItem('non_logged_in_user_id', userId);
	}
	return userId;
}