import React, { Component } from 'react';
import { ROUTES } from '../../services/navigation/navigation.routes';
import './ErrorBoundary.scss';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            window.location.href = ROUTES.maintenance;
        }

        return this.props.children;
    }
}

ErrorBoundary.displayName = 'ErrorBoundary';

export const ErrorMessage = () => {
    return (
        <div className='ErrorMessage'>
            <span>Oops! Something went wrong...</span>
        </div>
    )
};