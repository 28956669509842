import React, { Component } from 'react';
import { showHelpPage } from "../../services/navigation/navigation.service";
import { withTranslation } from 'react-i18next';
import { links } from '../../links';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { env } from '../../env';

class MemberPWReminder extends Component {

  state = {
    email: ''
  };

  onSupportClick = () => {
    SignInController.closeSignInDialog();
    showHelpPage();
  };

  isDataValid = () => {
    return this.state.email.length;
  };

  renderSendInfoButton = () => {
    const { t } = this.props;
    const classes = ['SendInfoButton'];
    let disabled = false;
    if (!this.isDataValid()) {
      classes.push('Disabled');
      disabled = true;
    }
    return (
      <button
        type="submit"
        disabled={disabled}
        className={classes.join(' ')}
      >
        {t('MemberPWReminder.sendMyInfo')}
      </button>
    );
  }

  onChange = (key, event) => {
    const { value } = event.target;
    this.setState({
      [key]: value
    });
  };

  sendInfoOnEnterIfValid = ({ key }) => {
    if (key === 'Enter' && this.isDataValid()) {
      this.signInUser();
    }
  };

  renderEmailInput = () => {
    const { email } = this.state;
    const { t } = this.props;
    return (
      <div className="InputWrapper">
        <i className="fas fa-user" />
        <input value={email}
          className="Input"
          name="email"
          type="email"
          placeholder={t('MemberPWReminder.loginEmail')}
          onChange={this.onChange.bind(this, 'email')}
          onKeyUp={this.sendInfoOnEnterIfValid}
        />
      </div>
    );
  };

  render() {
    const { t, notModal } = this.props;
    const propertyId = env.REACT_APP_PROPERTY_ID;
    return (
      <div className="MemberPWReminder">
        <div className="resetPwForm">
          <h2>{t('MemberPWReminder.subTitle')}</h2>
          {notModal && (
            <p className="PWReminderProblems">
              {t('MemberPWReminder.pleaseContact')} <span className='SupportLink' onClick={this.onSupportClick}>{t('MemberPWReminder.support')}</span>.
            </p>
          )}
          <form action={links.webMastersSignIn} method="GET">
            <input type="hidden" name="siteid" value={propertyId} />
            <input type="hidden" name="submit" value="1" />
            {this.renderEmailInput()}
            {this.renderSendInfoButton()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MemberPWReminder);