import React, { useEffect, Suspense } from 'react';
import { Helmet } from "react-helmet";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { clarity } from 'react-microsoft-clarity';

import LoadingMask, { LoadingText } from "./components/LoadingMask/LoadingMask";
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from "@falconstudios/ns-player";
import UserExit from "./components/UserExit/UserExit.js";

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
//import 'videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css';
import Div100vh from 'react-div-100vh';
import { handleTokenRefresh } from './services/token-service/token.service';
import { env } from './env';

import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';

import { isUserEngaged, getNonLoggedInUserId, generateUniqueId } from './services/user-engagement-service/user-engagement.service.js';

function App() {
  useEffect(() => {
    handleTokenRefresh();

    // Initialize session start time and page views count
    if (!localStorage.getItem('session_start_time')) {
      localStorage.setItem('session_start_time', Date.now().toString());
    }

    localStorage.setItem('page_views', (parseInt(localStorage.getItem('page_views'), 10) || 0) + 1);

    const hostname = window.location.hostname;
    console.log('Detected hostname:', hostname);

    if (hostname === "guerillaporn.com" || hostname === "shawns-machine.com") {
      console.log("Initializing Clarity for hostname:", hostname);
      clarity.init('m4b49wscqz'); // Directly using the Clarity project ID

      if (window.user && window.user.userId) {
        // Identify logged-in user immediately with Clarity
        const userId = window.user.userId;
        const userType = window.user.userType;
        const userStatus = window.user.userStatus;
        const logged = window.user.logged ? 'Yes' : 'No';

        clarity.identify(userId, {
          userType: userType,
          userStatus: userStatus,
          logged: logged,
          previousUserId: getNonLoggedInUserId() // Link previous non-logged-in data
        });

        // Update Google Analytics with logged-in user ID
        if (window.gtag) {
          window.gtag('config', env.REACT_APP_GA_MEASUREMENT_ID, {
            'user_id': userId
          });
        }

        // Clear the non-logged-in user ID from local storage
        localStorage.removeItem('non_logged_in_user_id');
      } else if (isUserEngaged()) {
        // Track the non-logged-in user only if they are engaged
        let userId = localStorage.getItem('non_logged_in_user_id');
        if (!userId) {
          userId = generateUniqueId();
          localStorage.setItem('non_logged_in_user_id', userId);
        }
        // Optionally, you can log this ID for tracking purposes
        console.log(`Non-logged-in user ID: ${userId}`);
      }
    } else {
      console.log("Clarity not initialized due to hostname mismatch.");
    }
  }, []);

  const reactappsitename = env.REACT_APP_SITE_NAME.replace(/\./g, '');

  return (
    <div className={`${reactappsitename}`}>
      <Div100vh>
        <div className="App">
          <Helmet>
            <title>{env.REACT_APP_SITE_TITLE}</title>
            <meta name="description" content={env.REACT_APP_SITE_DESCRIPTION} />
          </Helmet>
          <Suspense fallback={<LoadingText />}> {/* first loader is in index.html */}
            <MainDashboard />
          </Suspense>
          <MiniPlayerContainer />

          <LoadingMask /> {/* log in / out */}

          <ImagesLightBoxStyle /> {/* MovieDetailsPage image gallery (fas fa-camera icon) */}
        </div>
      </Div100vh>
      <UserExit />
    </div>
  );
}

export default App; 